import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  Loading,
  Error,
  EventRegistrationThemeProvider,
  EventPage,
  Header,
  Footer,
  RemoteConfigProvider,
  ScrollToTop,
  UserProvider,
} from "@sempra-event-registration/common";
import EventsPage from "./event/page/EventsPage/EventsPage";
import { useAnonymousFirebaseUser } from "./auth/useAnonymousFirebaseUser";

function AppProviders({ children }) {
  return (
    <RemoteConfigProvider>
      <UserProvider>
        <EventRegistrationThemeProvider company="sdge">
          {children}
        </EventRegistrationThemeProvider>
      </UserProvider>
    </RemoteConfigProvider>
  );
}

function AppContent() {
  const { status } = useAnonymousFirebaseUser();
  if (status === "loading" || status === "empty") return <Loading />;
  if (status === "error") return <Error message={"Signing in"} />;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header company="sdge" />
      <main>
        <Switch>
          <Route path="/events/:eventId">
            <EventPage />
          </Route>
          <Route exact path="/events">
            <EventsPage />
          </Route>
          <Redirect to={`/events${window.location.search}`} />
        </Switch>
      </main>
      <Footer company="sdge" />
    </BrowserRouter>
  );
}

function App() {
  return (
    <AppProviders>
      <AppContent />
    </AppProviders>
  );
}

export default App;
