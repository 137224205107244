import {
  firebaseReference,
  loadedResource,
  errorResource,
  loadingResource,
  UserContext,
} from "@sempra-event-registration/common";
import { useEffect, useContext } from "react";

/**
 * Gets the anonymous firebase user
 * This method will sign the user in if they are not already authenticated
 */
export function useAnonymousFirebaseUser() {
  const [state, setState] = useContext(UserContext);

  useEffect(() => {
    if (state.status === "empty") {
      setState(loadingResource());
      firebaseReference
        .auth()
        .setPersistence(firebaseReference.auth.Auth.Persistence.SESSION)
        .then(() => {
          firebaseReference
            .auth()
            .signInAnonymously()
            .then((result) => {
              if (result.user != null) {
                setState(loadedResource(result.user));
              } else {
                // This should never really happen
                setState(
                  errorResource(new Error(`Failed to sign in user anonymously`))
                );
              }
            })
            .catch((error) => {
              setState(errorResource(error));
            });
        });
    }
  }, [setState, state.status]);

  return state;
}
